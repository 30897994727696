<template>
  <div id="logout"></div>
</template>

<script>
export default {
  name: "logout",
  created() {
    localStorage.clear();
    this.$router.push({ name: "login" });
  },
};
</script>
